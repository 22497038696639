import { UserIcon } from "@heroicons/react/outline";
import React, { ReactElement } from "react";

type IntroductionType = {
	introduction: string[];
};

export const Introduction = ({
	introduction,
}: IntroductionType): ReactElement => {
	return (
		<section
			id="about"
			className="main-section mt-16 py-2 pb-3 md:pb-10 lg:pb-20 space-y-10 show-on-scroll scroll-mt-16"
			data-delay="0"
			data-show="right"
		>
			<div className="container mx-auto rounded-2xl bg-gray-100 shadow-2xl transform scale-95">
				<div className="container mx-auto rounded-2xl py-7 bg-emerald-500 shadow-2xl origin-center transform transition-transform ease-in duration-200">
					<div className="scale-95 container transform origin-center transition-transform ease-in duration-200 bg-white rounded-2xl shadow-2xl">
						<div className="font-sans text-4xl flex items-center gap-x-3 align-baseline font-bold rounded-t-2xl py-10 px-5 lg:p-10 bg-emerald-200 shadow text-emerald-900">
							<div className={"p-2 rounded-full bg-emerald-300 shadow-inner"}>
								<UserIcon className="text-emerald-900 h-9 w-auto m-1" />
							</div>
							<p className="show-on-scroll" data-delay="0" data-show="right">
								About Me
							</p>
						</div>
						<div
							className="container  py-5 px-10 text-xl leading-8 shadow-inner show-on-scroll tracking-wide"
							data-delay="0"
							data-show="right"
						>
							{introduction.map((value, key) => {
								return (
									<p key={key} className="pb-4 indent-10">
										{value}
										<br />
									</p>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
