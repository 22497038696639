import {
	TerminalIcon,
	CodeIcon,
	DesktopComputerIcon,
	CollectionIcon,
	DocumentReportIcon,
} from "@heroicons/react/outline";
import React, { Fragment, ReactElement } from "react";
import { LinksDTOType, ProjectDTOType } from "../utils/types";
import { ListElement } from "./elements/ListElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ProjectsType = {
	projects: ProjectDTOType[];
	links: LinksDTOType;
};

type LinkItemType = {
	url?: string;
	title: string;
};

const LinkItems = ({ url, title }: LinkItemType): ReactElement => {
	return (
		<Fragment>
			{url ? (
				<a
					className={
						"bg-red-200 text-red-800 hover:bg-red-600 hover:text-red-50 tag cursor-pointer child-show-on-scroll"
					}
					href={url}
					target="_blank"
					rel="noopener noreferrer"
					data-show={"right"}
					data-delay={50}
				>
					{title}
				</a>
			) : (
				<span
					className={"bg-red-200 text-red-800 tag child-show-on-scroll"}
					data-show={"right"}
					data-delay={50}
				>
					{title}
				</span>
			)}
		</Fragment>
	);
};

export const Projects = ({ projects, links }: ProjectsType): ReactElement => {
	return (
		<section
			id="projects"
			className="main-section show-on-scroll mt-2 pb-3 md:pb-10 lg:pb-20 space-y-10 scroll-mt-16"
			data-delay="0"
			data-show="left"
		>
			<div className="container mx-auto rounded-2xl bg-gray-100 shadow-2xl transform scale-95">
				<div className="container mx-auto rounded-2xl bg-red-500 shadow-2xl origin-center transform rotate-0 md:rotate-1 transition-transform ease-in duration-200 hover:rotate-0">
					<div className="scale-95 container transform origin-center rotate-0 md:-rotate-2 transition-transform ease-in duration-200 hover:rotate-0 bg-white rounded-2xl shadow-2xl">
						<div className="font-sans text-4xl flex items-center gap-x-3 flex-row-reverse lg:flex-row font-bold rounded-t-2xl py-10 px-5 lg:p-10 bg-red-200 shadow z-50 text-red-900">
							<a
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Projects"
								tabIndex={-1}
								href={links.github}
								className="transition-all ease-in-out duration-500 hover:text-black flex items-center justify-center"
							>
								<FontAwesomeIcon
									className={"text-red-800 mx-1"}
									icon={["fab", "github"]}
								/>
							</a>
							<p
								className="ml-auto ml-0 lg:ml-auto show-on-scroll mr-auto lg:mr-0"
								data-delay="0"
								data-show="left"
							>
								Projects
							</p>
							<div className={"p-2 rounded-full bg-red-300 shadow-inner"}>
								<TerminalIcon className="h-10 w-auto m-1" />
							</div>
						</div>
						<div className="grid grid-rows-1 divide-y-2 divide-red-100">
							{projects.map(
								(
									{
										title,
										codeToDoc,
										projectDataDTO,
										repoUrl,
										websiteUrl,
										duration,
										viewToDoc,
									},
									key
								) => {
									return (
										<div key={key} className="grid grid-cols-1 lg:grid-cols-3">
											<div className="py-5 px-10 leading-9 col-span-1 text-left lg:text-right text-lg font-bold text-red-900 bg-red-50 grid grid-rows-2">
												<div
													className="show-on-scroll"
													data-delay="0"
													data-show="left"
												>
													<p className="font-alegreya-sans-sc text-2xl flex w-full leading-none">
														<span className={"ml-0 lg:ml-auto leading-none"}>
															{title}
														</span>
													</p>
													<p className="font-alegreya-sans-sc text-lg text-red-600 opacity-75">
														<FontAwesomeIcon
															className={"text-base text-red-800 mx-1"}
															icon={["fas", "hourglass-half"]}
															data-delay={100}
															data-show={"left"}
														/>
														{duration}
													</p>
												</div>
												<div
													className="relative inline-flex ml-0 lg:ml-auto divide-x-2 divide-red-50 lg:mt-auto show-on-scroll mt-2"
													data-delay="0"
													data-show="left"
												>
													<a
														href={repoUrl}
														rel="noopener noreferrer"
														target="_blank"
														className={`relative flex justify-start items-center rounded-l-md p-1 px-4 font-bold hover:transition-all duration-500 ease-in-out ${
															repoUrl !== undefined
																? "shadow bg-red-200 hover:bg-red-600 opacity-80 hover:text-red-50"
																: "bg-red-50 opacity-75 cursor-not-allowed pointer-events-none"
														}
													`}
													>
														{codeToDoc ? (
															<DocumentReportIcon className="w-6 mr-2 align-middle" />
														) : (
															<CodeIcon className="w-6 mr-2 align-middle" />
														)}
														{codeToDoc ? "DOCS" : "CODE"}
													</a>
													<a
														href={websiteUrl !== undefined ? websiteUrl : "#"}
														rel="noopener noreferrer"
														target="_blank"
														className={`relative flex justify-start items-center rounded-r-md p-1 px-4 font-bold hover:transition-all duration-500 ease-in-out
															${
																websiteUrl !== undefined
																	? "shadow bg-red-200 hover:bg-red-600 opacity-80 hover:text-red-50"
																	: "bg-red-50 opacity-75 cursor-not-allowed pointer-events-none"
															}
														`}
													>
														{viewToDoc ? (
															<DocumentReportIcon className="w-6 mr-2 align-middle" />
														) : (
															<DesktopComputerIcon className="w-6 mr-2 align-middle" />
														)}
														{viewToDoc ? "DOCS" : "VIEW"}
													</a>
												</div>
											</div>
											<div className="container py-5 text-xl leading-9 col-span-2">
												<section
													className="show-on-scroll flex flex-col gap-y-3 px-10"
													data-delay="0"
													data-show="right"
												>
													{projectDataDTO?.map(
														({ projectData, title, linksData }, index) => {
															return (
																<div key={index}>
																	<p className="font-semibold leading-tight">
																		{title}
																	</p>
																	{projectData && (
																		<ul key={title} className="list-none">
																			{projectData.map((value, index) => (
																				<ListElement
																					key={index}
																					value={value}
																					bgColor={"bg-red-200"}
																					textColor={"text-red-900"}
																				/>
																			))}
																		</ul>
																	)}
																	<p className={"flex flex-wrap"}>
																		{linksData?.map(
																			({ link, title }, index) => (
																				<LinkItems
																					key={index}
																					url={link}
																					title={title}
																				/>
																			)
																		)}
																	</p>
																</div>
															);
														}
													)}
												</section>
											</div>
										</div>
									);
								}
							)}
							<a target="_blank" rel="noopener noreferrer" href={links.github}>
								<div className="flex justify-start items-center w-100 transition-all duration-300 ease-linear bg-red-100 rounded-b-2xl font-alegreya-sans-sc text-3xl py-6 text-center text-red-900 font-semibold hover:bg-red-300">
									<CollectionIcon className="w-8 mr-4 ml-auto align-middle" />
									<span
										className="mr-auto show-on-scroll"
										data-delay="0"
										data-show="right"
									>
										More Projects
									</span>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
