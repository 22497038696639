import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { MainContent } from "./components/MainContent";
import { WebsiteDataType } from "./utils/types";
import { getDatabase, ref, onValue } from "firebase/database";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	fab,
	faFacebookF,
	faGithub,
	faGoogle,
	faLinkedinIn,
	faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
	faCommentAlt,
	faEnvelope,
	far,
} from "@fortawesome/free-regular-svg-icons";
import {
	faBars,
	faBriefcase,
	faHourglassHalf,
	fas,
} from "@fortawesome/free-solid-svg-icons";
library.add(
	fab,
	fas,
	far,
	faLinkedinIn,
	faGithub,
	faCommentAlt,
	faGoogle,
	faHourglassHalf,
	faBriefcase,
	faEnvelope,
	faFacebookF,
	faWhatsapp,
	faBars
);

function App(): ReactElement {
	const [data, setData] = useState<WebsiteDataType | null>();

	useEffect(() => {
		const db = getDatabase();
		const dataRef = ref(db, "/MainData");

		onValue(dataRef, (snapshot) => {
			const data = snapshot.val();
			setData(data);
		});
	}, []);

	return data ? (
		<Fragment>
			<MainContent websiteData={data} />
		</Fragment>
	) : (
		<></>
	);
}

export default App;
