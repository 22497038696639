import React, { ReactElement, ReactNode } from "react";

type ListElementType = {
	value: ReactNode;
	bgColor?: string;
	textColor?: string;
	className?: string;
	icon?: ReactNode;
	animationDelay?: number;
};

export const ListElement = ({
	value,
	bgColor = "bg-gray-200",
	textColor = "bg-gray-900",
	className,
	icon = "i",
	animationDelay = 0,
}: ListElementType): ReactElement => {
	return (
		<li className={"flex flex-row gap-x-2 items-baseline"}>
			<span
				className={"h-5 w-5 child-show-on-scroll"}
				data-delay={animationDelay}
				data-show={"left"}
			>
				<span
					className={`h-5 w-5 shadow ${bgColor} font-cookie ${textColor} p-2 mr-2 rounded-full flex items-center justify-center ${className}`}
				>
					{icon}
				</span>
			</span>
			<span
				className={"align-baseline child-show-on-scroll"}
				data-delay={animationDelay}
				data-show={"right"}
			>
				{value}
			</span>
		</li>
	);
};
