import React, { ReactElement, useEffect, useState } from "react";
import { Coursework } from "./Coursework";
import { Experience } from "./Experience";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Introduction } from "./Introduction";
import Navbar from "./Navbar";
import { Resume } from "./Resume";
import { WebsiteDataType } from "../utils/types";
import { Projects } from "./Projects";
import { animate } from "../utils/animation";

type MainContentType = {
	websiteData: WebsiteDataType;
};

export const MainContent = ({ websiteData }: MainContentType): ReactElement => {
	const [progress, setProgress] = useState<number>(0);
	const handleScroll = (): void => {
		if (window.scrollY > window.innerHeight - 50) {
			document.getElementById("gotoTop")?.classList.add("scale-100");
			setProgress(
				((window.scrollY - window.innerHeight) /
					(document.body.scrollHeight - 2 * window.innerHeight)) *
					360
			);
		} else {
			document.getElementById("gotoTop")?.classList.remove("scale-100");
			setProgress(0);
		}
	};

	useEffect(() => {
		if (window.location.hash !== "") {
			const elem = document.querySelector(window.location.hash) as HTMLElement;
			window.scrollTo(0, elem.offsetTop);
		}

		window.addEventListener("scroll", handleScroll);
		animate(".show-on-scroll");

		return (): void => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<main className="font-mukta bg-white scroll-smooth relative">
			<button
				id="gotoTop"
				className="invisible lg:visible z-50 fixed bottom-5 right-7 pt-1 h-12 w-12 text-white text-2xl opacity-90
			                      border border-10 rounded-full shadow-lg hover:shadow-inner whitespace-nowrap overflow-hidden
			                      transform -rotate-90 bg-blue-800 hover:bg-blue-600 transition transition-all duration-400 ease-in-out transform scale-0 hover:scale-110"
				onClick={(): void => window.scrollTo(0, 0)}
			>
				&#8677;
			</button>
			<Navbar navigation={websiteData.navigation} />
			<Header aboutMe={websiteData.aboutMe} links={websiteData.links} />
			<Introduction introduction={websiteData.content.aboutMe} />
			<Experience experiences={websiteData.content.experiences} />
			<Coursework coursework={websiteData.content.coursework} />
			<Projects
				projects={websiteData.content.projects}
				links={websiteData.links}
			/>
			<Resume resumeLink={websiteData.links.resume} />
			<Footer links={websiteData.links} />
		</main>
	);
};
