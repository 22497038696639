import { DocumentTextIcon } from "@heroicons/react/outline";
import React, { ReactElement } from "react";
import { WebsiteDataType } from "../utils/types";

type ResumeType = {
	resumeLink: string;
};

export const Resume = ({ resumeLink }: ResumeType): ReactElement => {
	return (
		<section
			id="resume"
			className="bg-indigo-900 show-on-scroll"
			data-delay="0"
			data-show="up"
		>
			<a target="_blank" rel="noopener noreferrer" href={resumeLink}>
				<div className="group hover:text-gray-300 container flex mx-auto py-10 text-4xl font-bold text-gray-100 shadow-inner">
					<DocumentTextIcon className="h-10 w-auto mb-1 mr-4 ml-auto" />
					<p className="font-alegreya-sans-sc mr-auto">Resume</p>
				</div>
			</a>
		</section>
	);
};
