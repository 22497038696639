import React from "react";
import { render } from "react-dom";
import "./style/tailwind.css";
import App from "./App";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
	apiKey: "AIzaSyDpZFZ6Jez4EQVun8B1qeV_pz5Vytq83F4",
	authDomain: "mypersonalwebsite-b3da2.firebaseapp.com",
	databaseURL: "https://mypersonalwebsite-b3da2-default-rtdb.firebaseio.com",
	projectId: "mypersonalwebsite-b3da2",
	storageBucket: "mypersonalwebsite-b3da2.appspot.com",
	messagingSenderId: "578977779676",
	appId: "1:578977779676:web:41e3c361e5c4bb6272c919",
	measurementId: "G-TRYJGHG7Z2",
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

initializeAppCheck(app, {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	provider: new ReCaptchaV3Provider("6LfhIYIcAAAAANPjxrnH3h5CRaAk2H_BC40IiIQq"),

	// Optional argument. If true, the SDK automatically refreshes App Check
	// tokens as needed.
	isTokenAutoRefreshEnabled: true,
});

render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);
