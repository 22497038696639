import React, { ReactElement, useEffect, useState } from "react";
import { NavigationDTOType } from "../utils/types";

type NavbarType = {
	navigation: NavigationDTOType[];
};

function Navbar({ navigation }: NavbarType): ReactElement {
	const [show, setShow] = useState(false);

	const handleScroll = (): void => {
		if (window.scrollY > window.innerHeight / 2) {
			document
				.getElementById("navbar")
				?.classList.add(
					"shadow",
					"bg-gradient-to-tr",
					"from-emerald-600",
					"to-indigo-700"
				);
		} else {
			document
				.getElementById("navbar")
				?.classList.remove(
					"shadow",
					"bg-gradient-to-tr",
					"from-emerald-600",
					"to-indigo-700"
				);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return (): void => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [window.innerWidth, window.innerHeight]);

	return (
		<nav
			id="navbar"
			className={
				"min-h-1/8 overflow-hidden fixed w-full z-50 transition-colors duration-200 text-white show-on-scroll "
			}
			data-delay="3500"
			data-show="down"
		>
			<div className="container relative mx-auto flex-none lg:flex flex-row full-width transition ease-linear duration-100 scale-90 px-3">
				<div className="z-50 container mx-auto flex flex-row">
					<div
						className="mr-auto self-center px-3 py-4 font-caveat dark:text-white text-4xl rounded-2xl show-on-scroll transition-colors duration-100"
						data-delay="4250"
						data-show="left"
					>
						<button onClick={(): void => window.scrollTo(0, 0)}>AJ</button>
					</div>
					<button
						onClick={(): void => setShow(!show)}
						className="p-2 space-y-1 ring-transparent rounded-md block lg:hidden show-on-scroll transition-colors duration-100"
						data-delay="4250"
						data-show="left"
					>
						<div
							className={
								"bg-gray-300 w-7 h-1 rounded-sm transition-all duration-200 ease-linear"
							}
						/>
						<div
							className={
								"bg-gray-300 w-7 h-1 rounded-sm transition-all duration-200 ease-linear"
							}
						/>
						<div
							className={
								"bg-gray-300 w-7 h-1 rounded-sm transition-all duration-200 ease-linear"
							}
						/>
					</button>
				</div>
				<div
					className={`z-0 flex-none space-y-5 lg:space-y-0 lg:flex lg:flex-row space-x-0 lg:space-x-6 justify-end text-base font-sans font-semibold antialiased transition-all duration-100 ease-in-out ${
						!show && "-mt-68 invisible"
					} lg:mt-0 lg:visible`}
				>
					{navigation.map((val, key) => (
						<p key={key} style={{ margin: "0 0 0 0" }}>
							<button
								className="font-medium uppercase child-show-on-scroll py-6 px-3 transform hover:text-blue-100"
								data-delay={1000 + 350 * key}
								data-show="right"
								onClick={(): void => {
									setShow(false);
									window.scrollTo(
										0,
										(document.querySelector(val.link) as HTMLElement)
											.offsetTop - 50
									);
								}}
							>
								{val.title}
							</button>
						</p>
					))}
				</div>
			</div>
			{/*<div*/}
			{/*	style={{ width: showProgress.width + "%" }}*/}
			{/*	className={`h-2 p-0 m-0 bottom-0 ${*/}
			{/*		showProgress.width !== 0 ? "md:absolute lg:absolute" : "hidden"*/}
			{/*	} w-full`}*/}
			{/*>*/}
			{/*	<div className={"flex flex-row w-full gap-0 m-0 relative"}>*/}
			{/*		<div*/}
			{/*			style={{ width: showProgress.width + "%" }}*/}
			{/*			className={`h-2 bg-red-300 bottom-0 `}*/}
			{/*		/>*/}
			{/*		<div className={"w-2 bg-yellow-200 h-2"} />*/}
			{/*	</div>*/}
			{/*</div>*/}
		</nav>
	);
}

export default Navbar;
