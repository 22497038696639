import { OfficeBuildingIcon } from "@heroicons/react/outline";
import React, { Fragment, ReactElement, useState } from "react";
import { ExperienceDTOType } from "../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListElement } from "./elements/ListElement";

const ContentType = ({
	title,
	selection,
	experienceData,
}: ExperienceDTOType): ReactElement => {
	return (
		<Fragment>
			<div className="leading-tight font-alegreya-sans-sc leading-9 col-span-1 text-left lg:text-right text-2xl font-semibold text-blue-900 bg-blue-50 ">
				<div className={"show-on-scroll py-5"} data-delay={0} data-show="left">
					<p className={"px-10"}>{title}</p>
				</div>
			</div>
			<div className="container py-5 px-10 text-xl leading-9 col-span-2 grid gap-y-3">
				<div
					className={"flex flex-col gap-y-5 show-on-scroll"}
					data-delay={0}
					data-show={"right"}
				>
					{experienceData.map(({ title, duration, subTitle, data }, index) => (
						<div key={index}>
							{title && (
								<p
									className="leading-none text-2xl font-semibold font-alegreya-sans-sc child-show-on-scroll"
									data-delay={50}
									data-show={"right"}
								>
									{title}
								</p>
							)}
							<p
								className={`text-lg text-gray-600 leading-relaxed font-medium font-alegreya-sans-sc flex flex-wrap gap-x-6 mb-1 ${
									subTitle || duration ? "flex" : "hidden"
								}`}
							>
								{subTitle && (
									<span>
										<FontAwesomeIcon
											className={
												"text-base text-blue-900 bg-transparent mr-2 child-show-on-scroll"
											}
											icon={["fas", "briefcase"]}
											data-delay={100}
											data-show={"left"}
										/>
										<span
											className={"child-show-on-scroll"}
											data-delay={100}
											data-show={"right"}
										>
											{subTitle}
										</span>
									</span>
								)}
								{duration && (
									<span>
										<FontAwesomeIcon
											className={
												"text-base text-blue-900 bg-transparent mr-2 child-show-on-scroll"
											}
											icon={["fas", "hourglass-half"]}
											data-delay={100}
											data-show={"left"}
										/>
										<span
											className={"child-show-on-scroll"}
											data-delay={100}
											data-show={"right"}
										>
											{duration}
										</span>
									</span>
								)}
							</p>
							<ul className="list-none">
								{data.map((value, index) => (
									<ListElement
										key={index}
										value={value}
										bgColor={"bg-blue-200"}
										textColor={"text-blue-900"}
										animationDelay={100}
									/>
								))}
							</ul>
						</div>
					))}
				</div>
			</div>
		</Fragment>
	);
};

type ExperienceType = {
	experiences: ExperienceDTOType[];
};

export const Experience = ({ experiences }: ExperienceType): ReactElement => {
	return (
		<section
			id="experience"
			className="main-section mt-2 py-2 pb-3 md:pb-10 lg:pb-20 space-y-10 show-on-scroll scroll-mt-16"
			data-delay="0"
			data-show="left"
		>
			<div className="container mx-auto rounded-2xl bg-gray-100 shadow-2xl transform scale-95">
				<div className="container mx-auto rounded-2xl py-7 bg-blue-500 shadow-2xl origin-center transform transition-transform ease-in duration-200">
					<div className="scale-95 container transform origin-center transition-transform ease-in duration-200 bg-white rounded-2xl shadow-2xl">
						<div className="font-sans text-4xl flex items-center flex-row-reverse lg:flex-row gap-x-3 font-bold rounded-t-2xl py-10 px-5 lg:p-10 bg-blue-200 shadow text-blue-900">
							<p
								className="show-on-scroll mr-auto lg:mr-0 lg:ml-auto"
								data-delay="0"
								data-show="left"
							>
								Experience
							</p>
							<div className={"p-2 rounded-full bg-blue-300 shadow-inner"}>
								<OfficeBuildingIcon className="h-9 w-auto m-1" />
							</div>
						</div>
						<div className="grid grid-cols-1 lg:grid-cols-3 divide-y-2 divide-blue-100">
							{experiences.map(
								({ title, selection = false, experienceData }, index) => {
									return (
										<ContentType
											key={index}
											title={title}
											selection={selection}
											experienceData={experienceData}
										/>
									);
								}
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
