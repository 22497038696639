import React, { ReactElement } from "react";
import { LinksDTOType } from "../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type FooterType = {
	links: LinksDTOType;
};

export const Footer = ({ links }: FooterType): ReactElement => {
	return (
		<section
			id="contact"
			className=" bg-gray-100 bg-top bg-cover py-2 space-y-5"
		>
			<div className="px-4 lg:mx-auto w-full divide-y divide-gray-300">
				<div className="container mx-auto py-5 lg:flex">
					<div
						className="text-center lg:text-left show-on-scroll"
						data-delay="500"
						data-show="left"
					>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://github.com/Akshantjain/personalWebsite/tree/v4.0"
							className="mr-auto text-left font-light text-gray-700 text-sm hover:text-black transition-all ease-in-out duration-500 hover:underline font-mukta"
						>
							Designed and Developed by Akshant Jain
							<br />
							Copyright &copy; Akshant Jain 2021
						</a>
					</div>
					<div
						className="pt-3 text-center lg:py-0 ml-auto show-on-scroll flex flex-row items-center"
						data-delay="0"
						data-show="right"
					>
						<a
							target="_blank"
							rel="noopener noreferrer"
							tabIndex={-1}
							aria-label="social-email"
							href={links.email}
							className="hover:text-red-500 footer-icon"
						>
							<FontAwesomeIcon icon={["far", "envelope"]} />
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							tabIndex={-1}
							aria-label="social-github"
							href={links.github}
							className="footer-icon hover:text-black"
						>
							<FontAwesomeIcon icon={["fab", "github"]} />
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							tabIndex={-1}
							aria-label="social-linkedin"
							href={links.linkedIn}
							className="footer-icon hover:text-blue-500"
						>
							<FontAwesomeIcon icon={["fab", "linkedin-in"]} />
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							tabIndex={-1}
							aria-label="social-facebook"
							href={links.facebook}
							className="hover:text-blue-700 footer-icon"
						>
							<FontAwesomeIcon icon={["fab", "facebook-f"]} />
						</a>
						<a
							target="_blank"
							rel="noopener noreferrer"
							tabIndex={-1}
							aria-label="social-whatsapp"
							href={links.whatsapp}
							className="hover:text-emerald-500 footer-icon"
						>
							<FontAwesomeIcon icon={["fab", "whatsapp"]} />
						</a>
					</div>
				</div>
				<div className="container mx-auto py-5 flex">
					<div
						className="mr-auto self-center px-3 font-caveat font-bold text-gray-900 text-4xl rounded-2xl show-on-scroll"
						data-delay="0"
						data-show="left"
					>
						AJ
					</div>
					<p
						className="font-cookie px-3 text-4xl text-gray-900 drop-shadow-2xl subpixel-antialiased font-normal tracking-normal leading-tight show-on-scroll"
						data-delay="0"
						data-show="right"
					>
						Akshant Jain
					</p>
				</div>
			</div>
		</section>
	);
};
