import React, { ReactElement, useEffect, useState } from "react";
import { AboutDTOType, LinksDTOType } from "../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type HeaderType = {
	aboutMe: AboutDTOType;
	links: LinksDTOType;
};

export const Header = ({ aboutMe, links }: HeaderType): ReactElement => {
	const [loaded, setloaded] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setloaded(true);
		}, 3500);
	}, []);

	return (
		aboutMe && (
			<header className="header-clip bg-cover bg-no-repeat bg-gradient-to-tr from-emerald-600 to-indigo-700 h-screen font-sans py-20 px-12">
				<div className="h-full container mx-auto grid md:grid-cols-3 lg:grid-cols-3 justify-items-center justify-center`">
					<div className="flex col-span-2 self-end md:self-center lg:self-center flex-wrap content-center antialiased">
						<div className="-mt-10">
							<span
								className="font-black text-md lg:text-xl uppercase text-emerald-400 show-on-scroll"
								data-delay="100"
								data-show="right"
							>
								Hello{" "}
								<span role="img" aria-label="hi">
									&#128075;
								</span>
							</span>
							<br />
							<div
								id="name"
								className="name-animation static overflow-hidden border-r-2 border-transparent"
								data-delay="1500"
								data-show="left"
							>
								<div className="w-full text-5xl lg:text-8xl font-bold tracking-wider text-gray-100 whitespace-nowrap">
									{"I'm"} {aboutMe.firstName}
								</div>
							</div>
							<span
								className="w-full flex flex-wrap font-medium text-xl lg:text-2xl mt-4 text-gray-100 font-caveat show-on-scroll"
								data-delay="2000"
								data-show="left"
							>
								{" "}
								An enthusiast {aboutMe.headline}
								<br /> from {aboutMe.location}
							</span>
							<button
								className="border border-emerald-400 rounded-md text-emerald-400 font-medium shadow-lg active:shadow-inner active:opacity-100 active:text-emerald-200 active:border-emerald-200 font-alegreya-sans-sc show-on-scroll text-md px-4 py-3 mr-6 mt-6"
								data-delay="3000"
								data-show="left"
								onClick={(): void => {
									window.scrollTo(
										0,
										(document.getElementById("resume") as HTMLElement).offsetTop
									);
								}}
							>
								Contact Me
							</button>
							<button
								className="border border-emerald-400 rounded-md text-emerald-400 font-medium shadow-lg active:shadow-inner active:opacity-100 active:text-emerald-200 active:border-emerald-200 font-alegreya-sans-sc show-on-scroll text-md px-4 py-3 mr-6 mt-6"
								data-delay="3500"
								data-show="left"
								onClick={(): void => {
									window.scrollTo(
										0,
										(document.getElementById("contact") as HTMLElement)
											.offsetTop
									);
								}}
							>
								View Resume
							</button>
						</div>
					</div>
					<div
						className={`-mt-10 md:-ml-16 lg:-ml-16 grid col-span-2 md:col-span-1 lg:col-span-1 flex md:grid-cols-2 lg:grid-cols-2 place-items-center gap-6 -rotate-90 md:rotate-45 lg:-rotate-315 self-center md:self-center lg:self-center transition duration-1000 ease-in-out ${
							!loaded ? "opacity-0 scale-0" : "scale-100"
						} `}
					>
						<a
							href={links.email}
							tabIndex={-1}
							aria-label="social-email"
							className="place-self-end justify-self-end circular-icons"
						>
							<FontAwesomeIcon icon={["fab", "google"]} />
						</a>
						<a
							href={links.whatsapp}
							target="_blank"
							rel="noopener noreferrer"
							tabIndex={-1}
							aria-label="social-whatsapp"
							className="justify-self-start place-self-end circular-icons"
						>
							<FontAwesomeIcon icon={["far", "comment-alt"]} />
						</a>
						<a
							href={links.linkedIn}
							target="_blank"
							rel="noopener noreferrer"
							tabIndex={-1}
							aria-label="social-linkedin"
							className="md:place-self-start lg:place-self-start justify-self-end circular-icons"
						>
							<FontAwesomeIcon icon={["fab", "linkedin-in"]} />
						</a>
						<a
							href={links.github}
							target="_blank"
							rel="noopener noreferrer"
							tabIndex={-1}
							aria-label="social-github"
							className="place-self-start justify-self-start circular-icons"
						>
							<FontAwesomeIcon icon={["fab", "github"]} />
						</a>
					</div>
				</div>
			</header>
		)
	);
};
