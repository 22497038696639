import { BookOpenIcon } from "@heroicons/react/outline";
import React, { Fragment, ReactElement } from "react";
import { CourseworkDTOType } from "../utils/types";

const ContentType = ({
	title,
	data,
	dataType = "default",
}: CourseworkDTOType): ReactElement => {
	return (
		<Fragment>
			<div className="font-alegreya-sans-sc py-5 px-10 leading-9 col-span-1 text-left lg:text-right text-2xl font-semibold text-amber-900 bg-amber-50">
				<p className={"show-on-scroll"} data-delay={0} data-show={"left"}>
					{title}
				</p>
			</div>
			<div
				className=" container py-5 px-10 text-xl leading-9 flex flex-wrap gap-y-2 col-span-2 show-on-scroll"
				data-delay={0}
				data-show={"right"}
			>
				{data.map((val, key) => {
					return (
						<p
							key={key}
							className={`${
								dataType === "tags" && "tag text-yellow-800 bg-yellow-200"
							}`}
						>
							{val}
						</p>
					);
				})}
			</div>
		</Fragment>
	);
};

type CourseworkType = {
	coursework: CourseworkDTOType[];
};

export const Coursework = ({ coursework }: CourseworkType): ReactElement => {
	return (
		<section
			id="coursework"
			className="main-section mt-2 py-2 pb-3 md:pb-10 lg:pb-20 space-y-10 show-on-scroll scroll-mt-16"
			data-delay="0"
			data-show="right"
		>
			<div className="container mx-auto rounded-2xl bg-gray-100 shadow-2xl transform scale-95">
				<div className="container mx-auto rounded-2xl py-7 bg-amber-500 shadow-2xl origin-center transform transition-transform ease-in duration-200">
					<div className="scale-95 container transform origin-center transition-transform ease-in duration-200 bg-white rounded-2xl shadow-2xl">
						<div className="text-4xl font-sans flex items-center gap-x-3 font-bold rounded-t-2xl py-10 px-5 lg:p-10 bg-amber-200 shadow z-50  text-amber-900">
							<div className={"p-2 rounded-full bg-amber-300 shadow-inner"}>
								<BookOpenIcon className="h-10 w-auto m-1" />
							</div>
							<p className="show-on-scroll" data-delay="0" data-show="right">
								Coursework & Skills
							</p>
						</div>
						<div className="grid grid-cols-1 lg:grid-cols-3 divide-y-2 divide-amber-100">
							{coursework.map(({ title, data, dataType }, index) => (
								<ContentType
									key={index}
									title={title}
									data={data}
									dataType={dataType}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
