export const animate = function (
	className: string,
	target: Document | HTMLElement = document
): void {
	const animateChild = function (entries: any[]): void {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				setTimeout(() => {
					switch (entry.target.dataset.show) {
						case "right":
							entry.target.classList.add("animate-fadeSlideInRight");
							break;
						case "left":
							entry.target.classList.add("animate-fadeSlideInLeft");
							break;
						case "up":
							entry.target.classList.add("animate-fadeSlideInUp");
							break;
						case "down":
							entry.target.classList.add("animate-fadeSlideInDown");
							break;
						case "top":
							entry.target.classList.add("animate-fadeSlideInTop");
							break;
						case "bottom":
							entry.target.classList.add("animate-fadeSlideInBottom");
							break;
						default:
							break;
					}
					animate(".child-show-on-scroll", entry.target as HTMLElement);
				}, entry.target.dataset.delay);
			}
		});
	};

	const observer = new IntersectionObserver(animateChild);
	const targets = target.querySelectorAll(className);
	targets.forEach(function (target) {
		observer.observe(target);
	});
};
